.gallery-img-container {
  /* height: 500px; */

  width: 100%;
  overflow: hidden;
  text-align: center;
  align-items: center;

  transition: all 0.5s ease 0s;
}

.gallery-img-container:hover {
  box-shadow: 3px 10px 20px rgba(0, 0, 0, 0.1);

  color: #fff;
  transform: translateY(-4px);
  filter: brightness(130%);
}

.gallery-img {
  /* margin-left: 5%; */
  display: inline-block;
  width: 100%;
  /* height: 500px; */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  /* padding-left: 2px; */
  /* padding-right: 20px; */

  /* width: 100%; */
}

.gallery-emote {
  width: 30px;
  max-width: 30%;
  position: relative;
  margin-top: -25px;
}

.img-description {
  text-align: left;
  font-family: Arial, Helvetica, sans-serif !important;
  color: white !important;
  padding-bottom: 15px;
}

.album-info {
  color: white;
  text-decoration: none !important;
}

.album-info:hover {
  color: gray;
  text-decoration: none !important;
}

.album-title {
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 1px;

  /* color: white; */
  text-decoration: none;
}

.album-artist {
  /* font-size: medium; */
  font-size: 1rem;
  /* letter-spacing: 1px; */
  margin-top: -17px;
  /* font-size: 1.9rem; */
  /* text-align: center; */

  /* color: white; */
  text-decoration: none;
}

.gallery-container {
  margin-top: 57px;
}

.gallery-header {
  margin-top: 57px;
  /* text-align: left !important; */
}

/* .contact-container {
  position: relative;
  min-height: 100vh;
} */
