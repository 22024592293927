.video-container {
  /* position: relative;
  min-height: 300px;
  max-height: 800px;
  overflow: hidden;
  margin-bottom: 500px; */
  height: 100vh;
  position: absolute;
  /* left: -500px; */
  /* left: -700px; */
}

/* Style the video: 100% width and height to cover the entire window */
.video {
  margin-top: 0;
  /* width: 100%; */
  height: 100vh;
  /* left: 0; */

  /* position: absolute; */
  /* z-index: -1; */
  /* height: 100vh; */
  /* width: auto; */
  /* height: 100%; */
}

.video-thumb {
  position: absolute;
  /* z-index: 4; */
  height: 100vh;
  /* top: -100px; */
  left: -300px;
  /* height: 100%;
  width: 100%; */
  transition: opacity 400ms ease 0ms;
}
/* .tiny {
  filter: blur(20px);
  transform: scale(1.1);
  transition: visibility 0ms ease 400ms;
} */

/* Add some content at the bottom of the video/page */
.Content {
  position: absolute;
  top: 0;
  color: #f1f1f1;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.SubContent {
  text-align: center;
  padding: 10px;
}
.SubContent p {
  color: #eeeeeead;
  font-size: 1.3rem;
}
.SubContent button {
  font-weight: bold;
  margin-top: 30px;
  color: #f1f1f1;
  border: #f1f1f1 1px solid;
  padding: 10px 5px;
  background-color: hsla(0, 0%, 0%, 0);
  font-size: 1.25rem;
  border-radius: 5px;
}
.SubContent button:hover {
  background-color: #f1f1f1;
  color: #000000c0;
}

.SubContent img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  border: #f1f1f1 1px solid;
}

@media only screen and (min-width: 600px) {
  .video-container {
    /* left: -300px; */
  }

  .video-thumb {
    /* left: 100px; */
  }
}

@media only screen and (min-width: 1200px) {
  .video-container {
    top: -300px;
    /* left: -700px; */
    height: 180vh;
  }
  .video {
    /* left: 700px; */
    height: 180vh;
  }
}

/* @media only screen and (min-width: 1400px) {
  .video-container {
    left: 0px;
  }
} */
