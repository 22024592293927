body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: rgb(255, 158, 174) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.center-image {
  position: relative;
  text-align: center;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 20px;
  width: 30%;
}

.act-btn {
  text-decoration: none !important;
  /* width: 20%; */
  display: flex;
  margin-top: 20px;
  /* margin: 20px; */
  /* margin-top: 60vh; */
  /* margin-left: 75vw; */
  font-family: "Montserrat", sans-serif;
  font-size: 0.8rem;
  font-weight: 700;
  padding: 15px;
  color: black;
  background: white;
  justify-content: center;

  text-decoration: none;
  /* border: 1px; */
  border: solid white 1px;

  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  /* transition: all 0.5s ease 0s; */
  cursor: pointer;
  outline: none;
}
.act-btn:hover {
  color: white;
  background-color: black;
  text-decoration: none;
  box-shadow: 3px 10px 20px rgba(0, 0, 0, 0.1);
}

/* Laptop screens:  */

@media only screen and (min-width: 600px) {
  .home-image {
    width: 50%;
  }

  .act-btn {
    width: 50%;
  }
}
