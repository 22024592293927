.map-container {
  position: relative;
  min-height: 100vh;
  margin-top: 6vh;

  /* text-align: center; */
}

.map-header {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;
  margin-top: 20px;
  margin-bottom: 20px;
}

.request-success-alert {
  position: fixed;
  top: 85vh;
  left: 10%;
  /* color: blue; */
  background-color: #43a047 !important;
  /* height: 0px; */
  padding: 10px;
  width: 80%;

  font-size: large;
  font-family: Arial, Helvetica, sans-serif;

  border-radius: 15px;
}

.map-title {
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  font-weight: bolder;
  text-align: center;
  margin-top: 6vh;
  font-size: 1.9rem;
}

.map-text {
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  font-weight: bolder;
  text-align: center;
}

.map-chart-container {
  text-align: center;
}

.map-chart-header {
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
  font-size: 1.9rem;
  text-align: center;
  font-weight: bolder;

  /* margin-top: 20px;
  /* margin-bottom: 20px; */
  /* color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;  */
}

.map-selected-content {
  color: white !important;

  text-align: center;

  font-family: Arial, Helvetica, sans-serif;
  /* letter-spacing: 5px; */
  font-size: 1.9rem;
  text-align: center;

  padding-bottom: 100px;
  /* margin-left: 20%; */
  /* position: relative; */
}

.act-btn {
  width: 80%;

  margin: 20px;
  margin-left: 10%;
  /* margin-top: 60vh; */
  /* margin-left: 75vw; */
}

.green-btn-bg {
  background-color: #43a047 !important;
  border: none !important;
}

.gray-btn-bg {
  background-color: #9e9e9e !important;
  border: none !important;
}

.btns-container {
  /* width: 50%; */
  /* margin-left: 25%; */
}

/* Laptop screens:  */

@media only screen and (min-width: 600px) {
  .map-chart-container {
    margin-left: 25%;
    width: 50%;
  }

  .act-btn {
    width: 50%;

    margin-left: 25%;
  }

  .map-selected-content {
    /* padding-left: 20%; */
  }
}

.map-spacer {
  height: 20vh;
}

.request-data {
  font-size: medium;
  font-weight: normal;
}
