.home-container {
  height: 100vh;
  position: fixed;
}

.content-wrapper {
  padding-top: 1vh;
  padding-bottom: 5vh;
}

.landing-title {
  position: absolute;
  /* left: 5px;
  top: 5px; */

  top: 7vh;
  left: 5%;

  color: white;
  display: flex;
  left: 0px;
  margin-left: 4vw;
  font-size: 4rem;
  /* letter-spacing: 20px; */
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
  z-index: 3;

  /* text-align: center;  */
  /* ? */
}

/* .custom-header {
  color: rgb(63, 63, 63);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 1.9rem;
  text-align: center;
  margin: 20px 0px 10px 0px;
} */

.home-text {
  position: relative;
  color: rgb(63, 63, 63);
  top: 40vh;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 1.9rem;
}

.home-bg-color {
  background-color: whitesmoke !important;
}

.landing-btn {
  text-decoration: none !important;
  position: fixed;
  display: flex;
  width: 50%;
  bottom: 7vh;
  right: 5%;

  font-family: "Montserrat", sans-serif;
  font-size: 0.8rem;
  font-weight: 700;
  padding: 15px;
  color: white !important;
  background: rgba(255, 255, 255, 0);
  justify-content: center;
  text-decoration: none;
  border: solid white 1px;

  /* box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1); */
  /* transition: all 0.5s ease 0s; */
  cursor: pointer;
  outline: none;
}

/* Laptop screens:  */

@media only screen and (min-width: 600px) {
  .home-image {
    width: 50%;
  }

  .landing-btn {
    width: 20%;
  }
}

@media only screen and (min-width: 1200px) {
  .video-container {
    left: 0px;
  }

  .landing-title {
    /* top: ; */
    left: 15vw;
  }

  .landing-btn {
    /* top: ; */
    right: 15vw;
  }
}
