.shows-img-container {
  position: fixed;
  z-index: -1;
  height: 110%;
  min-width: 100%;
  overflow: hidden;
  top: -0px;
  left: -400px;
}

.shows-container {
  position: relative;
  min-height: 100vh;
  text-align: center;
  margin-top: 6vh;
}

.shows-header {
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  letter-spacing: 1px;
  font-size: 2.4rem;
  text-align: center;
  font-weight: bolder;
}

.news-title {
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  text-align: left;
  font-size: 1.4rem;
  font-weight: 600;
}

.news-date  {
  color: rgb(51, 51, 51);
}

.news-body {
  color: white;
  text-align: left;
  margin-top: 2vh;
}

.news-post {
  margin-top: 10vh;
}



@media only screen and (min-width: 600px) {
  .center-image {
    width: 20%;
  }

  .shows-header {
    font-size: 3.5rem;
  }
  .shows-img-container {
    position: fixed;
    z-index: -1;
    height: 100vh;
    /* min-width: 100%; */
    left: 0px;
    overflow: hidden;
  }
  .shows-list {
    margin-top: 4vh;
  }
}
