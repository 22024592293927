.join-img {
  position: fixed;
  z-index: -1;
  height: 100vh;
  min-width: 100%;
}

.join-form-bg {
  /* min-height: 100vh; */
  /* background-color: black; */
  width: 300px;
  margin: auto;
  height: 30vh;
  position: relative;
  top: 30vh;
  /* padding-top: 4.5vh; */
}

.join-container {
  position: relative;
  min-height: 100vh;
  text-align: center;
}

.join-header {
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;
  padding-top: 3vh;
}

.join-input {
  width: 200px;
  margin: auto;
  text-align: center;
}

.join-btn:hover {
  color: white;
  background-color: black;
  border: solid white 1px;
  text-decoration: none;
  box-shadow: 3px 10px 20px rgba(0, 0, 0, 0.1);
}

.join-btn-container {
  /* height: 100%; */
  width: 200px;
  margin: auto;
  display: flex;
  position: relative;
}

.join-btn {
  color: rgb(255, 158, 174) !important;
  background-color: rgb(255, 255, 255) !important;
  border: solid white 1px;
  text-decoration: none;
  box-shadow: 3px 10px 20px rgba(0, 0, 0, 0.1);

  text-decoration: none !important;
  position: relative;
  width: 100%;
  display: flex;
  top: 2vh;
  margin: auto;
  font-family: "Montserrat", sans-serif;
  font-size: 0.8rem;
  font-weight: 700;
  padding: 15px;
  justify-content: center;
  /* text-decoration: none; */
  /* border: none; */
  /* box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1); */
  transition: all 0.5s ease 0s;
  cursor: pointer;
  outline: none;
}

/* alert stuff  */

.success-alert {
  background-color: #43a047;

  color: white;
}

.sending-alert {
  background-color: #42a5f5;
  color: white;
}

.sending-alert-text {
  /* display: inline-block; */
  position: absolute;
  top: 1vh;
  left: 34%;
}

.alert {
  position: absolute;
  top: 60vh;
  left: 10%;

  /* margin-top: 5vh; */
  height: 5vh;
  width: 80%;
  text-align: center;
  padding: 5px;
  border-radius: 2px;
  /* padding: 5px;
  padding-top: 10px; */
  /* padding-bottom: 30px; */
  /* padding: 10px;
  width: 100%;
  text-align: center;
  border-radius: 45px; */
  /* padding-top: 1vh; */
}

.fail-alert {
  background-color: #e53935;

  color: white;
}

.success-alert-text {
  font-size: small;
  text-align: left;
}

@media only screen and (min-width: 600px) {
  .join-header {
    padding-top: 5vh;
  }

  .sending-alert-text {
    left: 40%;
  }

  .alert {
    padding: 10px;
    width: 30%;
    left: 35%;
  }
}
