.footer-container {
  width: 100% !important;
  height: 150px;
  background-color: white;
  text-align: center;
  margin-bottom: 50px;
}

.footer-wrapper {
  padding-top: 20px;
}

.footer-text {
  color: rgb(255, 158, 174);

  font-weight: 100;
  font-size: small;
  text-align: center;
}

.footer-links {
  text-align: right;
}

.footer-link {
  text-decoration: none;
  color: rgb(255, 158, 174);
  font-size: small;
  margin-bottom: 0 !important;
  padding: 0 !important;
}

.footer-links-title {
  margin: 0;
}

.footer-company-name {
  display: inline;
  color: rgb(255, 158, 174);
}

.footer-company-icon {
  display: inline;
  width: 20px;
  margin-right: 5px;
}

.footer-icons {
  text-align: center;
  padding-top: 2vh;
  font-size: x-large;
}

.footer-icon {
  padding-left: 10px;
  padding-right: 10px;
  color: rgb(255, 158, 174);

  padding-bottom: 10px;
}

.footer-icon:hover {
  color: red;
}
